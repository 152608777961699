<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="6"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <div>
              <h1 class="custom-header-title float-left pr-1 mb-0">
                Create New Year
              </h1>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center d-flex justify-content-end mb-1 mb-md-0"
      >
        <div class="demo-inline-spacing roster_group_dropdown create_group">
          <!-- primary -->
          <b-dropdown
            id="dropdown-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            text="Roster Groups"
            variant="outline-primary"
          >
            <b-dropdown-text style="width: 240px;">
              Designated day off for roster groups
            </b-dropdown-text>
            <b-dropdown-item
              v-for="(option, key) in rosterGroups"
              :key="key"
            >
              <div>
                <span>{{ option.name }} ({{ option.dayOffDaysNumber }})</span>
                <div class="text-nowrap">
                  <span
                    v-for="(day, index) in option.dayOffDays"
                    :key="index"
                  >
                    {{ day }} <span v-if="index + 1 != option.dayOffDays.length">, </span>
                  </span>
                </div>
              </div>
            </b-dropdown-item>
            <b-dropdown-text>
              <b-button
                v-if="canViewThisAction('create', 'RosterGroup')"
                variant="primary"
                :to="{ name: 'operation-roster-groups-create' }"
              >
                Add Roster Group
              </b-button>
            </b-dropdown-text>
          </b-dropdown>
        </div>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <validation-observer
      ref="annualGroupingCreateForm"
      #default="{invalid}"
    >
      <b-form
        class="roster__grouping grouping__form annual__grouping"
        @submit.prevent="submitForm"
      >
        <b-card class="group__create_top">
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Year*"
                label-for="h-annual-grouping-year"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Year"
                  vid="year"
                  rules="required"
                >
                  <b-form-input
                    id="h-annual-grouping-year"
                    v-model="year"
                    placeholder="Four digit year"
                    :state="(errors.length > 0 || yearValidation) ? false : null"
                    name="year"
                    @input="yearValidation == true ? yearValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="yearValidation"
                    class="text-danger"
                  >
                    {{ yearError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="6" />
          </b-row>
        </b-card>
        <div class="group__bottom">
          <b-table-simple
            ref="teamsTable"
            class="position-relative has_padding"
            responsive
            hover
          >
            <b-thead head-variant="light">
              <b-tr>
                <b-th width="16%">
                  Staff Name
                </b-th>
                <b-th
                  width="7%"
                  class="text-left"
                >
                  Jan
                </b-th>
                <b-th
                  width="7%"
                  class="text-left"
                >
                  Feb
                </b-th>
                <b-th
                  width="7%"
                  class="text-left"
                >
                  Mar
                </b-th>
                <b-th
                  width="7%"
                  class="text-left"
                >
                  Apr
                </b-th>
                <b-th
                  width="7%"
                  class="text-left"
                >
                  May
                </b-th>
                <b-th
                  width="7%"
                  class="text-left"
                >
                  Jun
                </b-th>
                <b-th
                  width="7%"
                  class="text-left"
                >
                  Jul
                </b-th>
                <b-th
                  width="7%"
                  class="text-left"
                >
                  Aug
                </b-th>
                <b-th
                  width="7%"
                  class="text-left"
                >
                  Sep
                </b-th>
                <b-th
                  width="7%"
                  class="text-left"
                >
                  Oct
                </b-th>
                <b-th
                  width="7%"
                  class="text-left"
                >
                  Nov
                </b-th>
                <b-th
                  width="7.14%"
                  class="text-left"
                >
                  Dec
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(team, key) in teams"
                :key="key"
              >
                <b-td
                  class="no-padding-td"
                  colspan="13"
                >
                  <b-table-simple
                    ref="relatedUserTable"
                    class="position-relative"
                    responsive
                    hover
                  >
                    <b-thead>
                      <b-tr>
                        <b-th colspan="10">
                          {{ team.name }}
                        </b-th>
                        <b-th
                          colspan="3"
                          class="text-right"
                        >
                          <div class="action_button">
                            <b-button
                              v-show="key != 0"
                              variant="flat-primary"
                              @click="moveTeam(key, key-1)"
                            >
                              <feather-icon
                                icon="ArrowUpIcon"
                                class="mr-50"
                                size="16"
                              />
                            </b-button>
                            <b-button
                              v-show="key != (teams.length - 1)"
                              variant="flat-primary"
                              @click="moveTeam(key, key+1)"
                            >
                              <feather-icon
                                icon="ArrowDownIcon"
                                class="mr-50"
                                size="16"
                              />
                            </b-button>
                          </div>
                        </b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody class="white-body-background">
                      <b-tr
                        v-for="(user, index) in team.users"
                        :key="index"
                      >
                        <b-td width="16%">
                          {{ user.name }}
                        </b-td>
                        <b-td
                          width="7%"
                          class="td-no-padding-right"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Group"
                            vid="group"
                            rules=""
                          >
                            <v-select
                              id="h-annual-grouping-status"
                              v-model="annualGrouping[user._id].jan"
                              label="abbreviation"
                              placeholder="N/A"
                              :options="rosterGroups"
                              :reduce="abbreviation => abbreviation._id"
                              :clearable="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-td>
                        <b-td
                          width="7%"
                          class="td-no-padding-right"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Group"
                            vid="group"
                            rules=""
                          >
                            <v-select
                              id="h-annual-grouping-status"
                              v-model="annualGrouping[user._id].feb"
                              label="abbreviation"
                              placeholder="N/A"
                              :options="rosterGroups"
                              :reduce="abbreviation => abbreviation._id"
                              :clearable="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-td>
                        <b-td
                          width="7%"
                          class="td-no-padding-right"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Group"
                            vid="group"
                            rules=""
                          >
                            <v-select
                              id="h-annual-grouping-status"
                              v-model="annualGrouping[user._id].mar"
                              label="abbreviation"
                              placeholder="N/A"
                              :options="rosterGroups"
                              :reduce="abbreviation => abbreviation._id"
                              :clearable="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-td>
                        <b-td
                          width="7%"
                          class="td-no-padding-right"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Group"
                            vid="group"
                            rules=""
                          >
                            <v-select
                              id="h-annual-grouping-status"
                              v-model="annualGrouping[user._id].apr"
                              label="abbreviation"
                              placeholder="N/A"
                              :options="rosterGroups"
                              :reduce="abbreviation => abbreviation._id"
                              :clearable="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-td>
                        <b-td
                          width="7%"
                          class="td-no-padding-right"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Group"
                            vid="group"
                            rules=""
                          >
                            <v-select
                              id="h-annual-grouping-status"
                              v-model="annualGrouping[user._id].may"
                              label="abbreviation"
                              placeholder="N/A"
                              :options="rosterGroups"
                              :reduce="abbreviation => abbreviation._id"
                              :clearable="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-td>
                        <b-td
                          width="7%"
                          class="td-no-padding-right"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Group"
                            vid="group"
                            rules=""
                          >
                            <v-select
                              id="h-annual-grouping-status"
                              v-model="annualGrouping[user._id].jun"
                              label="abbreviation"
                              placeholder="N/A"
                              :options="rosterGroups"
                              :reduce="abbreviation => abbreviation._id"
                              :clearable="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-td>
                        <b-td
                          width="7%"
                          class="td-no-padding-right"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Group"
                            vid="group"
                            rules=""
                          >
                            <v-select
                              id="h-annual-grouping-status"
                              v-model="annualGrouping[user._id].jul"
                              label="abbreviation"
                              placeholder="N/A"
                              :options="rosterGroups"
                              :reduce="abbreviation => abbreviation._id"
                              :clearable="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-td>
                        <b-td
                          width="7%"
                          class="td-no-padding-right"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Group"
                            vid="group"
                            rules=""
                          >
                            <v-select
                              id="h-annual-grouping-status"
                              v-model="annualGrouping[user._id].aug"
                              label="abbreviation"
                              placeholder="N/A"
                              :options="rosterGroups"
                              :reduce="abbreviation => abbreviation._id"
                              :clearable="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-td>
                        <b-td
                          width="7%"
                          class="td-no-padding-right"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Group"
                            vid="group"
                            rules=""
                          >
                            <v-select
                              id="h-annual-grouping-status"
                              v-model="annualGrouping[user._id].sep"
                              label="abbreviation"
                              placeholder="N/A"
                              :options="rosterGroups"
                              :reduce="abbreviation => abbreviation._id"
                              :clearable="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-td>
                        <b-td
                          width="7%"
                          class="td-no-padding-right"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Group"
                            vid="group"
                            rules=""
                          >
                            <v-select
                              id="h-annual-grouping-status"
                              v-model="annualGrouping[user._id].oct"
                              label="abbreviation"
                              placeholder="N/A"
                              :options="rosterGroups"
                              :reduce="abbreviation => abbreviation._id"
                              :clearable="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-td>
                        <b-td
                          width="7%"
                          class="td-no-padding-right"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Group"
                            vid="group"
                            rules=""
                          >
                            <v-select
                              id="h-annual-grouping-status"
                              v-model="annualGrouping[user._id].nov"
                              label="abbreviation"
                              placeholder="N/A"
                              :options="rosterGroups"
                              :reduce="abbreviation => abbreviation._id"
                              :clearable="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-td>
                        <b-td
                          width="7%"
                          class="td-no-padding-right"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Group"
                            vid="group"
                            rules=""
                          >
                            <v-select
                              id="h-annual-grouping-status"
                              v-model="annualGrouping[user._id].dec"
                              label="abbreviation"
                              placeholder="N/A"
                              :options="rosterGroups"
                              :reduce="abbreviation => abbreviation._id"
                              :clearable="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              :to="{ name: 'operation-roster-annual-groupings-index' }"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="invalid"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Save Year</span>
            </b-button>
          </li>
        </b-nav>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BNav, BTableSimple, BThead, BTbody, BTr, BTh, BTd,
  BDropdown, BDropdownItem, BDropdownText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import 'array.prototype.move'
import { required, digits } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
// import Cleave from 'vue-cleave-component'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BNav,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BDropdown,
    BDropdownItem,
    BDropdownText,

    vSelect,
    // Cleave,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      canViewThisAction,
      savedData: false,
      year: '',
      yearError: 'Valid Year is required',
      yearValidation: false,
      teams: [],
      rosterGroups: [],
      annualGrouping: [],

      // validation rules
      required,
      digits,
    }
  },
  beforeMount() {
    this.$http.get('operation/roster-annual-groupings/respond-with/teams')
      .then(response => {
        this.teams = response.data.teams ?? []
        this.rosterGroups = response.data.rosterGroups ?? []
        const newUserObject = {}
        this.teams.forEach(team => {
          team.users.forEach(arrayItem => {
            const newObj = {}
            newObj.jan = null
            newObj.feb = null
            newObj.mar = null
            newObj.apr = null
            newObj.may = null
            newObj.jun = null
            newObj.jul = null
            newObj.aug = null
            newObj.sep = null
            newObj.oct = null
            newObj.nov = null
            newObj.dec = null

            newUserObject[arrayItem._id] = newObj
            // userObj.value = newObj
            // newUserObject.push(userObj)
          })
        })

        this.annualGrouping = newUserObject
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  created() {
    if (this.$route.query.clone) {
      this.$http.get(`operation/roster-annual-groupings/respond-with-on-edit/teams/${this.$route.query.clone}`)
        .then(response => {
          this.teams = response.data.teams ?? []
          this.year = response.data.rosterYear.name ?? ''
          this.rosterGroups = response.data.rosterGroups ?? []
          this.annualGroupingValues = response.data.annualGroupingValues ?? []

          this.teams.sort((a, b) => response.data.rosterYear.teamPriority.indexOf(a._id) - response.data.rosterYear.teamPriority.indexOf(b._id))
          const newUserObject = {}
          this.teams.forEach(team => {
            team.users.forEach(arrayItem => {
              const newObj = {}

              const exists = this.annualGroupingValues.find(o => o.user === arrayItem._id)
              if (exists) {
                newObj.jan = exists.jan
                newObj.feb = exists.feb
                newObj.mar = exists.mar
                newObj.apr = exists.apr
                newObj.may = exists.may
                newObj.jun = exists.jun
                newObj.jul = exists.jul
                newObj.aug = exists.aug
                newObj.sep = exists.sep
                newObj.oct = exists.oct
                newObj.nov = exists.nov
                newObj.dec = exists.dec
              } else {
                newObj.jan = null
                newObj.feb = null
                newObj.mar = null
                newObj.apr = null
                newObj.may = null
                newObj.jun = null
                newObj.jul = null
                newObj.aug = null
                newObj.sep = null
                newObj.oct = null
                newObj.nov = null
                newObj.dec = null
              }

              newUserObject[arrayItem._id] = newObj
              // userObj.value = newObj
              // newUserObject.push(userObj)
            })
          })

          this.annualGrouping = newUserObject
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.savedData) {
      this.$swal({
        title: 'Unsaved Changes - Leave?',
        html: 'You have unsaved changes. Are you sure you want to leave this page?',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: "Yes, I'm Sure",
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-outline-primary no-focus-bg ml-1',
          cancelButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            next()
          } else {
            next(false)
          }
        })
    } else {
      next()
    }
  },
  methods: {
    moveTeam(from, to) {
      this.teams.move(from, to)
    },
    submitForm() {
      this.$refs.annualGroupingCreateForm.validate().then(success => {
        if (success) {
          const teamPriorityArray = []
          this.teams.forEach(team => {
            teamPriorityArray.push(team._id)
          })
          const formData = new FormData()

          formData.append('year', this.year)
          formData.append('teamPriority', JSON.stringify(teamPriorityArray))
          formData.append('annualGrouping', JSON.stringify(this.annualGrouping))

          this.$http.post('operation/roster-annual-groupings/store', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                this.$swal({
                  title: 'Annual Grouping Created.',
                  html: response.data.message || '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  allowOutsideClick: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(result => {
                    this.savedData = true
                    if (result.value) {
                      this.$router.push({ name: 'operation-roster-annual-groupings-index' })
                    }
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'year') {
                    this.yearError = validationError.msg
                    this.yearValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style scoped>
  table td.no-padding-td {
    padding: 12px 0px !important
  }
  table td.td-no-padding-right {
    padding: 12px 8px !important
  }
  .white-body-background {
    background-color: white;
  }
  .v-select {
    width: 95px;
  }
</style>
